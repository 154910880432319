






























import {Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Component from 'vue-class-component';
import {Form} from '@/types';
import Recaptcha from '@/components/register/Recaptcha.vue';
import ErrorMessage from '@/components/commons/ErrorMessage.vue';

const AuthStore = namespace('AuthStore');

@Component({
  name: 'FirstStep',
  components: {Recaptcha, ErrorMessage},
})
export default class FirstStep extends Vue {

  public $refs!: {
    form: Form,
    $email: any,
    recaptcha: Recaptcha,
  };

  @Prop({required: true})
  public model: any;

  @Prop({required: true, type: Function})
  public nextStep!: () => void;

  @Prop({required: true, type: Function})
  public goToLastTab!: () => void;

  @AuthStore.State
  public errorMessage!: string;

  @AuthStore.Mutation
  public setRecoveryMail!: (newRecoveryMail: string) => Promise<void>;

  @AuthStore.Mutation
  public setSuccessMessage!: (newSuccessMessage: string) => Promise<void>;

  @AuthStore.Mutation
  public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

  public showResetPass: boolean = false;

  public emailRules = {
    email: [
      {
        required: true,
        message: 'O e-mail é obrigatório',
        trigger: 'blur',
      },
      {
        type: 'email',
        message: 'O formato do e-mail não é válido',
        trigger: 'blur',
      },
      {
        validator: async (rule, value, callback) => {
          const res = await this.$axios.get(`usuario/exists/email/${value}`);
          if (res.data === false) {
            this.setShowResetPass(false); // this não é a instancia do Vue
            callback();
          } else {
            this.setShowResetPass(true); // this não é a instancia do Vue
            callback(new Error('E-mail já cadastrado! Utilize o link "Esqueci minha senha" para recuperar seu acesso'));
          }
        },
        trigger: 'blur',
      },
    ],
  };

  public rules: any = {
    ...this.emailRules,
  };

  public setShowResetPass(value: boolean): void {
    this.showResetPass = value;
  }

  public validate(resolve, reject): void {
    this.$refs.form.validate(async (valid) => {
      if (!valid) {
        resolve(false);
      } else {
        if (!this.$refs.recaptcha.isChecked) {
          this.setErrorMessage('Preencha o reCAPTCHA para avançar!');
          resolve(false);
        } else {
          this.setErrorMessage('');
          await this.existsCode(this.model.email);
        }
      }
      resolve(valid);
    });
  }

  public async existsCode(email: string): Promise<any> {
    const res = await this.$axios.get('auth/exists-code', {params: {email}});
    if (res.status === 200) {
      this.setSuccessMessage(res.data);
      this.setErrorMessage('');
      this.goToLastTab();
    }
  }

  public forgot() {
    this.setRecoveryMail(this.model.email);
    this.$router.push('forgot');
  }

  public submit(): void {
    this.nextStep();
  }

  public setFocus(): void {
    this.$nextTick(() => this.$refs.$email.focus());
  }
}
