



























import {Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Component from 'vue-class-component';
import {Form} from '@/types';

const AuthStore = namespace('AuthStore');

@Component({
  name: 'SecondStep',
})
export default class SecondStep extends Vue {

  public $refs!: {
    form: Form,
    $name: any,
  };

  @Prop({required: true})
  public model: any;

  @Prop({required: true, type: Function})
  public nextStep!: () => void;

  @AuthStore.Mutation
  public setSuccessMessage!: (newSuccessMessage: string) => Promise<void>;

  @AuthStore.Mutation
  public setWarningMessage!: (newWarningMessage: string) => Promise<void>;

  @AuthStore.Mutation
  public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

  public nameRules: any = {
    name: [
      {
        required: true,
        message: 'O nome é obrigatório',
        trigger: 'blur',
      },
      {
        min: 5,
        message: 'O nome deve ter no mínimo 5 caracteres',
        trigger: 'blur',
      },
    ],
  };

  public foneRules: any = {
    fone: [
      {
        required: true,
        message: 'O telefone é obrigatório',
      },
      {
        min: 10,
        message: 'O telefone deve ter no mínimo 10 caracteres',
        trigger: 'blur',
      },
    ],
  };

  public passwordRules: any = {
    password: [
      {
        required: true,
        message: 'Senha é obrigatório',
        trigger: 'blur',
      },
      {
        min: 6,
        message: 'A senha deve ter no mínimo 6 caracteres',
        trigger: 'blur',
      },
      {
        validator: (rule, value, callback) => {
          if (!/[A-Z]/.test(value)) {
            callback(new Error('A senha deve conter pelo menos um caractere maiúsculo'));
          }
          if (!/\d/.test(value)) {
            callback(new Error('A senha deve conter pelo menos um número'));
          }
          if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
            callback(new Error('A senha deve conter pelo menos um caractere especial'));
          }
          callback();
        },
        trigger: 'blur',
      },
    ],
  };

  public confirmRules: any = {
    confirm: [
      {
        required: true,
        message: 'Confirmar a senha é obrigatório',
        trigger: 'blur',
      },
      {
        validator: (rule, value, callback) => {
          if (value === this.model.password) {
            callback();
          } else {
            callback(new Error('As senhas informadas não correspondem.'));
          }
        },
        trigger: 'blur',
      },
    ],
  };

  public rules: any = {
    ...this.nameRules,
    ...this.foneRules,
    ...this.confirmRules,
    ...this.passwordRules,
  };

  public async sendVerificationCode(): Promise<any> {
    this.model.login = this.model.email;
    await this.$axios.post(`/usuario/verify/${this.model.email}`, this.model)
    .then((response) => {
      if (!response.data) {
        this.setErrorMessage('Erro ao criar código de verificação.');
        this.setSuccessMessage('');
        this.setWarningMessage('');
      } else {
        this.setSuccessMessage('');
        this.setErrorMessage('');
        this.setWarningMessage('Informe o código de verificação enviado para o seu e-mail.');
      }
    })
    .catch((error) => {
      this.setErrorMessage('Erro ao criar código de verificação.');
      this.setSuccessMessage('');
      this.setWarningMessage('');
    });
  }

  public validate(resolve, reject): void {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        await this.sendVerificationCode();
      }
      resolve(valid);
    });
  }

  public submit(): void {
    this.nextStep();
  }

  public setFocus(): void {
    this.$nextTick(() => this.$refs.$name.focus());
  }
}
