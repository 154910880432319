





















































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {AppInfo} from '@/models/AppInfo';
import FirstStep from '@/components/register/FirstStep.vue';
import SecondStep from '@/components/register/SecondStep.vue';
import ThirdStep from '@/components/register/ThirdStep.vue';
import Copyright from '@/components/commons/Copyright.vue';
import TermsAndPolicy from '@/components/terms/TermsAndPolicy.vue';

const AppInfoStore = namespace('AppInfoStore');
const AuthStore = namespace('AuthStore');

@Component({
    name: 'register',
    components: {FirstStep, SecondStep, ThirdStep, Copyright, TermsAndPolicy},
})
export default class Register extends Vue {

    public $refs!: {
        stepForm: any,
        firstStep: FirstStep,
        thirdStep: ThirdStep,
        secondStep: SecondStep,
        copyright: Copyright,
    };

    @AppInfoStore.State
    public info!: AppInfo;

    @AuthStore.State
    public errorMessage!: string;

    @AuthStore.Mutation
    public setSuccessMessage!: (newSuccessMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setWarningMessage!: (newWarningMessage: string) => Promise<void>;

    @AuthStore.Mutation
    public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

    public loading: boolean = false;

    public windowWidth: number = window.innerWidth;

    public model: any = {
        name: '',
        login: '',
        email: '',
        fone: '',
        password: '',
        confirm: '',
        verify: '',
    };

    public get getRootElement(): Element {
      return document.querySelector(':root') as Element;
    }

    public get tabColor() {
      return getComputedStyle(this.getRootElement).getPropertyValue('--color-primary');
    }

    public get tabColorError() {
      return getComputedStyle(this.getRootElement).getPropertyValue('--color-danger');
    }

    public mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    }

    public beforeChangeFirstStep(): Promise<any> {
        this.loading = true;
        return new Promise((resolve, reject) => this.$refs.firstStep.validate(resolve, reject))
            .finally(() => this.loading = false);
    }

    public async beforeChangeSecondStep(): Promise<any> {
        this.loading = true;
        return new Promise((resolve, reject) => this.$refs.secondStep.validate(resolve, reject))
            .finally(() => this.loading = false);
    }

    public beforeChangeThirdStep(): Promise<any> {
        this.loading = true;
        return new Promise((resolve, reject) => this.$refs.thirdStep.validate(resolve, reject))
            .finally(() => this.loading = false);
    }

    public reqNewVerificationCode(): void {
        this.model.login = this.model.email;
        this.$axios.post(`/usuario/verify/${this.model.email}`, this.model)
            .then((response) => {
                if (!response.data) {
                    this.setErrorMessage('Erro ao criar código de verificação.');
                    this.setSuccessMessage('');
                    this.setWarningMessage('');
                } else {
                    this.setSuccessMessage('');
                    this.setErrorMessage('');
                    this.setWarningMessage('Informe o código de verificação enviado para o seu e-mail.');
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    this.setErrorMessage('O e-mail informado não possui uma conta cadastrada.');
                } else {
                    this.setErrorMessage('Erro inesperado ao tentar gerar código de verificação.');
                }
                this.setWarningMessage('');
                this.setSuccessMessage('');
            });
    }

    get sideWidth(): string {
        const collapsedWidth = '60px';
        const expandedWidth = window.screen.availWidth <= 380 ? window.screen.availWidth + 'px' : '200px';
        return this.info!.sidebarCollapsed ? collapsedWidth : expandedWidth;
    }

    public clean(): void {
        this.loading = false;
        Object.keys(this.model).forEach((key) => this.model[key] = '');
    }

    public nextStep(): void {
        this.$refs.stepForm.nextTab();
    }

    public afterChangeFirstStep(): void {
        this.$refs.firstStep.setFocus();
    }

    public afterChangeSecondStep(): void {
        this.$refs.secondStep.setFocus();
    }

    public afterChangeThirdStep(): void {
        this.$refs.thirdStep.setFocus();
    }

    public goToLastTab(): void {
        this.$refs.stepForm.tabs[1].checked = true;
        this.$refs.stepForm.changeTab(0, 2);
    }

    public back() {
        this.clean();
        this.$router.push('login');
    }
}
