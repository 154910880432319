


















import {Prop, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Component from 'vue-class-component';
import {Form} from '@/types';
import SuccessMessage from '@/components/commons/SuccessMessage.vue';
import WarningMessage from '@/components/commons/WarningMessage.vue';
import ErrorMessage from '@/components/commons/ErrorMessage.vue';

const AuthStore = namespace('AuthStore');

@Component({
  name: 'ThirdStep',
  components: {SuccessMessage, WarningMessage, ErrorMessage},
})
export default class ThirdStep extends Vue {

  public $refs!: {
    form: Form,
    verify: any,
  };

  @Prop({required: true})
  public model: any;

  @Prop({required: true, type: Function})
  public nextStep!: () => void;

  @AuthStore.State
  public successMessage!: string;

  @AuthStore.State
  public warningMessage!: string;

  @AuthStore.State
  public errorMessage!: string;

  @AuthStore.Mutation
  public setSuccessMessage!: (newSuccessMessage: string) => Promise<void>;

  @AuthStore.Mutation
  public setWarningMessage!: (newWarningMessage: string) => Promise<void>;

  @AuthStore.Mutation
  public setErrorMessage!: (newErrorMessage: string) => Promise<void>;

  public verifyRules: any = {
    verify: [
      {
        required: true,
        message: 'O código de verificação é obrigatório',
        trigger: 'blur',
      },
    ],
  };

  public rules: any = {
    ...this.verifyRules,
  };

  public async validateConfirmationCode(valid): Promise<boolean> {
    this.model.login = this.model.email;
    await this.$axios.post('/auth/valid-code', this.model)
      .then((response) => {
        this.setSuccessMessage('Sua conta foi confirmada com sucesso. Você será redirecionado para a página de login.');
        this.setErrorMessage('');
        this.setWarningMessage('');
        setTimeout(() => this.$router.push('login'), 3000);
        valid = true;
      })
      .catch((error) => {
        if (error.response) {
          this.setSuccessMessage('');
          this.setWarningMessage('');
          this.setErrorMessage(error.response.data);
        }
        valid = false;
      });
    return valid;
  }

  public validate(resolve, reject): void {
    this.$refs.form.validate(async (valid) => {
      if (valid) {
        resolve(await this.validateConfirmationCode(valid));
      }
      resolve(valid);
    });
  }

  public submit(): void {
    this.nextStep();
  }

  public setFocus(): void {
    this.$nextTick(() => this.$refs.verify.focus());
  }
}
